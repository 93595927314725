/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const B612Mono_400Regular = require('./B612Mono_400Regular.ttf');
export const B612Mono_400Regular_Italic = require('./B612Mono_400Regular_Italic.ttf');
export const B612Mono_700Bold = require('./B612Mono_700Bold.ttf');
export const B612Mono_700Bold_Italic = require('./B612Mono_700Bold_Italic.ttf');
